.loginFormWrapper {
    position: relative;
}
.divStyleOpen, 
.divStyleClosed
{    
    background:white;
    color:black;
    position:absolute;
    padding: 10px;
    right: 0px;
    top: 0px;
    margin: 0px 0px;
    border-radius: 5px;
    box-shadow: 1px 1px 3px grey;
}
.divStyleOpen{
    display:block;
}
.divStyleClosed {
    display:none;
}